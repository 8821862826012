

const ourPartners = [
   
   {
       id: 1,
     Banklog: "/Access.png",
       BankName: "Access Bank" ,
      
},

// {
//    id: 2,
//  Banklog: "/Chartered.png",
//    BankName: "Standard Chartered Bank",
  
// },
{
   id: 3,
 Banklog: "/Fidelity.png",
   BankName: "Fidelity Bank",
  
},
{
   id: 3,
 Banklog: "/ZenithBank.png",
   BankName: "Zenith Bank",
  
},
{
  id: 4,
Banklog: "/axa-logo.png",
  BankName: "Zenith Bank",
 
},


]

export default ourPartners;