
const Values = [

    
    {

        
        info: "Luxury",
        symbol: "/luxury.png"
        
    },

   
    {

        
        info: "Integrity",
        symbol: "/trail.png"
        
    },

    {

        
        info: "Excellence",
        symbol: "/excellence.png"
        
    },
    {

        
        info: "Reliability",
        symbol: "/Impact.png"
        
    },
    {

        
        info: "Innovation",
        symbol: "/innovate.png"
        
    },

    {

        
        info: " Team Work",
        symbol: "/connection.png"
        
    },
    {

        
        info: "Productivity",
        symbol: "/environment.png"
        
    },

    {

        
        info: "Accountability",
        symbol: "/growth.png"
        
    },
    {

        
        info: "Customer Services",
        symbol: "/networking.png"
        
    },

   
   
   
   
   
   
   

   


]

export default Values;